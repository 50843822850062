import React from "react";
import Modal from "react-bootstrap/Modal";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import PrimaryButton from "../PrimaryButton";

const VipPlayer = ({ show, setShow, bonusCredit, promoText }) => {

  const RocketmanVipImage = "img/rocketman-vip.png";
  return (
    <Modal
      className="pop-up astronaut-popup vip-popup"
      show={show}
      onHide={() => setShow(false)}
      animation={false}
      backdropClassName="over-popup-backdrop"
      enforceFocus={false}
    >
      <Modal.Body>
        <div className="astronaut-pop-up-body">
          <img src={RocketmanVipImage} alt="astronaut-promo-credit" />
          <div className="pop-up-promo-text pop-up-promo-text-vip">
            <div className="pop-up-promo-text-holder">
              <h1 className="pupt-vissible">{promoText}</h1>
              <h1 className="pupt-hidden">{promoText}</h1>
            </div>
          </div>
          <div className="pop-up-message-box">
            <p className="pop-up-message-description">
              {translate('vip_popup_text')}
            </p>
          </div>
          <div className="pop-up-close">
            <PrimaryButton
              title={translate('ok_close')}
              onClick={() => setShow(false)}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VipPlayer;
