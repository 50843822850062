import React from "react";
import Modal from "react-bootstrap/Modal";
import translate from "../../services/Translation/lang";
import PrimaryButton from "../PrimaryButton";

const InsurancePromo = ({ show, setShow, promoText }) => {

  const AdvancedAutoplayPreviewClose = "img/advanced-autoplay-preview-close.png";
  const BetInsurancePromo = "img/bet-insurance-promo.png";
  return (
    <Modal
      className="pop-up astronaut-popup advanced-autoplay-promo-popup bet-insurance-promo"
      show={show}
      onHide={() => setShow(false)}
      animation={false}
      backdropClassName="over-popup-backdrop"
      enforceFocus={false}
      onClick={() => setShow(false)}
    >
      <Modal.Body>
        <div className="astronaut-pop-up-body">
          <img
            className="aap-close"
            src={AdvancedAutoplayPreviewClose}
            onClick={() => setShow(false)}
            alt="advanced-autoplay-preview-close"
          />
            <img className="bet-insurance-promo-image" src={BetInsurancePromo} alt="bet-insurance-promo"/>

          <div className="pop-up-promo-text pop-up-promo-text-vip">
            <div className="pop-up-promo-text-holder">
              <h1 className="pupt-vissible">{promoText}</h1>
              <h1 className="pupt-hidden">{promoText}</h1>
            </div>
          </div>
          <div className="pop-up-message-box">
            <p className="pop-up-message-description">
              {/* {translate('vip_popup_text')} */}
              {translate('bet_insurance_promo_text')}
              <br /><br />
              <i>{translate('bet_insurance_promo_text_helper')}</i>
            </p>
          </div>
          <div className="pop-up-close">
            <PrimaryButton
              title={translate('ok_close')}
              onClick={() => setShow(false)}
            />
          </div>
        </div>
        
      </Modal.Body>
      
    </Modal>
  );
};

export default InsurancePromo;
