import React, { useEffect, useRef, useState } from 'react'
import MeteorShowerPopup from './MeteorShowerPopup';
import translate from '../../services/Translation/lang';

export default function MeteorShowerBar() {

  const [showMeteorPopup, setShowMeteorPopup] = useState(true);

  const MeteorShowerImage = "img/meteor-shower/meteor-shower.png";

  const meteorPopupRef = useRef(null);

  const [clickedOutside, setClickedOutside] = useState(false);

  const handleClickOutside = (e) => {
    if (!meteorPopupRef.current.contains(e.target)) {
      setClickedOutside(true);
      setShowMeteorPopup(true);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const submitMeteorRain = () => {
    setShowMeteorPopup(true);
  }

  return (
    <div ref={meteorPopupRef} onClick={handleClickInside}>
      {!showMeteorPopup ? <MeteorShowerPopup setShowMeteorPopup={setShowMeteorPopup} submitMeteorRain={submitMeteorRain} /> : null}
      <div onClick={() => setShowMeteorPopup(!showMeteorPopup)} className='meteor-shower-bar'>
        <img src={MeteorShowerImage} alt="meteor-shower" />
        <p>{translate('meteor_shower')}</p>
      </div>
    </div>
  )
}
