import React, { useEffect, useState } from "react";
import ArrowUpPlayNowSvg from "../components/svg/arrow-up-play-now-svg";
import soundFxPlayer from "../services/Audio/SoundFxPlayer";
import rocketmanTransport from "../services/RocketmanTransport";
import FullscreenPlayNowFooter from "./svg/fullscreen-play-now-footer";

const PlayNowFooter = ({ multipliersHistory }) => {
  const [footerActive, setFooterActive] = useState(false);
  const [footerFullscreen, setFooterFullscreen] = useState(false);

  useEffect(() => {
    if (footerActive) {
      soundFxPlayer.play("scroll_1");
    } else {
      soundFxPlayer.play("scroll_2");
    }
  }, [footerActive]);

  useEffect(() => {
    if (footerFullscreen) {
      soundFxPlayer.play("scroll_1");
    } else {
      soundFxPlayer.play("scroll_2");
    }
  }, [footerFullscreen]);

  const toggleActive = () => {
    setFooterActive(!footerActive);
    if (footerFullscreen) {
      setFooterActive(false);
      setFooterFullscreen(false);
    }
  };

  let highLight;

  highLight = (mp) => {
    if (Number(mp) > 2 && Number(mp) <= 9.99) return "win";
    if (Number(mp) > 9.99 && Number(mp) <= 99.99) return "win-big";
    if (Number(mp) > 99.99) return "win-legendary";

    return "win-small";
  };

  return (
    <div
      className={
        "play-now-footer " +
        (footerActive ? "active " : " ") +
        (footerFullscreen ? "fullscreen " : " ")
      }
    >
      {multipliersHistory.length > 0 ? (
        <div className="play-now-footer-toggle-wrapper">
          <button
            onClick={() => toggleActive()}
            className={
              "play-now-footer-toggle " + (footerActive ? "active" : "")
            }
          >
            <ArrowUpPlayNowSvg />
          </button>
        </div>
      ) : null}
      <div className="container">
        <div className="play-now-footer-mmsb-holder">
          {multipliersHistory
            .slice(0)
            .reverse()
            .map((mp, index) => {
              return (
                <p
                  className={["mmsb-info-content", highLight(mp)].join(" ")}
                  key={index}
                >
                  {mp ? mp.toFixed(2) : ""}x
                </p>
              );
            })}
        </div>
      </div>
      {multipliersHistory.length > 0 &&
        footerActive && (
          <div className="play-now-footer-fullscreen-wrapper">
            <button
              onClick={() => setFooterFullscreen(!footerFullscreen)}
              className={
                "play-now-footer-fullscreen " +
                (footerFullscreen ? "fullscreen" : "")
              }
            >
              <FullscreenPlayNowFooter />
            </button>
          </div>
        )}
    </div>
  );
};

export default PlayNowFooter;
