import React from "react";
import avatars from "../model/avatars";
import Flag from "../components/Flag/Flag";
import TableShareSvg from "../components/svg/table-share-svg";
import rocketmanTransport from "../services/RocketmanTransport";
import { canShare, checkEachCanShare } from "../services/SpamFilter/TicketShareSpamFilter";
import Currency from "./Currency/Currency";
import { currencyFormatter } from "../services/Helpers/NumberFormatter";
import translate from "../services/Translation/lang";

const AllBetsTrTotal = (props) => {
  const highLight = (mp) => {
    if (Number(mp) > 2 && Number(mp) <= 9.99) return "win";
    if (Number(mp) > 9.99 && Number(mp) <= 99.99) return "win-big";
    if (Number(mp) > 99.99) return "win-legendary";

    return "win-small";
  };

  const shareTicket = () => {
    if (canShare(props.coefficient, props.winEur)) {
      const msgParams = {
        avatar: props.avatar,
        countryCode: rocketmanTransport.countryCode,
        username: rocketmanTransport.username,
        entry: translate("good_job_buddy"),
        toUser: props.username,
        cashedOut: props.coefficient.toFixed(2),
        winAmount: props.win,
        betAmount: props.bet,
        winEur: props.winEur,
        betEur: props.betEur,
        betCurrency: props.betCurrency,
        round: props.round,
      };
      const sharedPrefix = "sharedBet-RCF2408-";
      const msg = sharedPrefix + JSON.stringify(msgParams);

      return rocketmanTransport.requestSendChatMessage(msg);
    }
  };

  return (
    <tr className={"all-bets-tr " + (rocketmanTransport.r7css ? "r7css" : "")}>
      <td>
        <div className="all-bets-td-holder">
          <div className="all-bets-tr-avatar">
            <img src={avatars[props.avatar]} alt="" />
          </div>
          <span className="all-bets-tr-username">
            {props.username}
            {props.isVip ? (
              <img
                className="table-vip-avatar-marker"
                src="svg/crown.svg"
                alt="crown"
              />
            ) : null}
          </span>
          <div className="all-bets-tr-flag">
            {props.flagCode === "XS" ? (
              <Flag className="xs-flag" code={"RU"} height="10" width="14" />
            ) : (
              <Flag code={props.flagCode} height="10" width="14" />
            )}
          </div>
        </div>
      </td>
      <td>
        {props.bet < 0.01
          ? "<0.01"
          : props.bet % 1 !== 0
          ? currencyFormatter(Number(props.bet))
          : currencyFormatter(Number(props.bet))}{" "}
        <Currency>{props?.valute}</Currency>
      </td>
      <td>
        <span>
          {props.win ? (
            <span
              className={[
                "all-bets-tr-coefficient",
                highLight(props.coefficient),
              ].join(" ")}
            >
              {props.coefficient ? props.coefficient.toFixed(2) : ""}x
            </span>
          ) : (
            <span> --- </span>
          )}
        </span>
      </td>
      <td>
        {props.win ? (
          <>
            <span>
              {props.win < 0.01
                ? "<0.01"
                : props.win % 1 !== 0
                ? currencyFormatter(Number(props.win))
                : currencyFormatter(Number(props.win))}{" "}
                <Currency>{props?.valute}</Currency>
            </span>
          </>
        ) : (
          <span> --- </span>
        )}
      </td>
      {rocketmanTransport.isChatVisible ? <td className="share">
        <button
          className={["table-share", !props.win || !checkEachCanShare(props.coefficient,props.winEur) ? "disabled" : ""].join(" ")}
          onClick={() => (props.win  && checkEachCanShare(props.coefficient,props.winEur) ? shareTicket() : () => null)}
        >
          <TableShareSvg />
        </button>
      </td> : null}
    </tr>
  );
};

export default AllBetsTrTotal;
