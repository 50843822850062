import moment from "moment";
import "moment/locale/sr";
import { useEffect, useState } from "react";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import TournementCounterComponent from "./TournementCounterComponent";
import Currency from "../Currency/Currency";

// const placeAwards =
//   "1000,500,200,100,100,100,50,50,50,30,30,30,20,15,10,10,10,10,5,5,5,5,5,5,5,5,5,5,5,5,5";

// Do not remove placeAwards and placeAwardsSplit, its for testing purpose

const TournamentCounter = () => {
  const [showTournamentCounter, setShowTournamentCounter] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [tournamentStatus, setTournamentStatus] = useState(0);
  // const placeAwardsSplit = placeAwards.split(",");

  const index2awardPlaces = [
    translate("first"),
    translate("second"),
    translate("third"),
    // "4th-10th",
    // "11th-20th",
    // "21th-30th"
  ];

  const getDuplicatesIndex = (a) => {
    var duplicates = {};
    for (var i = 0; i < a.length; i++) {
      if (duplicates.hasOwnProperty(a[i])) {
        duplicates[a[i]].push(i);
      } else if (a.lastIndexOf(a[i]) !== i) {
        duplicates[a[i]] = [i];
      }
    }

    return duplicates;
  };

  window.setCounterTournamentStatus = setTournamentStatus;

  const compareWithNext = (a, b) => {
    if (a > b) {
      return false;
    } else if (a === b) return true;
  };

  const findIndex = (i, item) => {
    let tempArray = getDuplicatesIndex(rocketmanTransport.tournamentInfoPlaceAwards);

    if (i < index2awardPlaces.length) {
      return index2awardPlaces[i];
    } else if (compareWithNext(rocketmanTransport.tournamentInfoPlaceAwards[i], rocketmanTransport.tournamentInfoPlaceAwards[i + 1])) {
      if (tempArray.hasOwnProperty(item)) {
        let tempIndex = tempArray[item].slice(-1);

        return `${i + 1}th - ${Number(tempIndex) + 1}th`;
      }
    } else return i + 1 + "th";
  };

  useEffect(() => {
    setStartTime(rocketmanTransport.tournamentStartTime);
    setEndTime(rocketmanTransport.tournamentEndTime);
    moment.locale("sr");
  }, [tournamentStatus]);

  return (
    <div>
      <div
        onClick={() => setShowTournamentCounter(!showTournamentCounter)}
        className={
          "tournament-counter-holder " + (!showTournamentCounter ? "hide" : "") + (tournamentStatus == 3 ? " tournament-in-progress" : "") + (rocketmanTransport.launcherHideHeader == 1 ? " no-header" : "")
        }
      >
        <div className="tournament-counter-header">
          <img src="svg/tournament/tournament.png" alt="tournament" />
          <img
            className="tournament-notif"
            src="svg/tournament/tournament-notif.png"
            alt="tournament-notif"
          />
        </div>
        {(startTime && tournamentStatus == 2 && <TournementCounterComponent startTime={startTime} />) ||
          null}
        {(endTime && tournamentStatus == 3  && <TournementCounterComponent startTime={endTime} tournamentInProgress />) ||
          null}
      </div>
      <div
        className={
          "tournament-info-holder " + (showTournamentCounter ? "hide" : "")
        }
      >
        <div className="tournament-info-title">
          {rocketmanTransport.tournamentName}
          <button
            className="close-container bet-info-close"
            onClick={() => setShowTournamentCounter(!showTournamentCounter)}
          >
            <div className="leftright"></div>
            <div className="rightleft"></div>
          </button>
        </div>
        <div className="tournament-info-time">
          <h5> {translate("schedule")}</h5>
          <div className="tournament-info-time-box">
            <div className="titb-from-to">
              <span className="titb-from-to-text">{translate("from")}</span>
              <img
                className="titb-image"
                src="svg/tournament/tournament-calendar.png"
                alt="tournament-calendar"
              />
              {moment(rocketmanTransport.tournamentStartTime).format("DD.MM.")}
              <img
                className="titb-image"
                src="svg/tournament/tournament-from-to.png"
                alt="tournament-from-to"
              />
              {moment(rocketmanTransport.tournamentStartTime).format("HH:mm")}
            </div>
            <div className="titb-from-to">
              <span className="titb-from-to-text">{translate("to")}</span>
              <img
                className="titb-image"
                src="svg/tournament/tournament-calendar.png"
                alt="tournament-calendar"
              />
              {moment(rocketmanTransport.tournamentEndTime).format("DD.MM.")}
              <img
                className="titb-image"
                src="svg/tournament/tournament-from-to.png"
                alt="tournament-from-to"
              />
              {moment(rocketmanTransport.tournamentEndTime).format("HH:mm")}
            </div>
          </div>
        </div>
        <div className="tournament-info-prizes">
          <h5>{translate("prizes")}</h5>
          <div className="tournament-info-prizes-holder">
            {rocketmanTransport.tournamentInfoPlaceAwards.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    "tournament-info-prizes-col " +
                    (compareWithNext(
                      rocketmanTransport.tournamentInfoPlaceAwards[index],
                      rocketmanTransport.tournamentInfoPlaceAwards[index - 1]
                    ) && index > 2
                      ? "double"
                      : "")
                  }
                >
                  <div className="tournament-info-prizes-box">
                    {index < 3 ? (
                      <img
                        className="tournament-info-prizes-image"
                        src={`/svg/tournament/ranks/tournament-${
                          index === 0
                            ? "first"
                            : index === 1
                            ? "second"
                            : index === 2
                            ? "third"
                            : ""
                        }.png`}
                        alt="tournament-calendar"
                      />
                    ) : null}
                    <h6>
                      {findIndex(index, item)}
                    </h6>
                    <p className="tournament-info-prizes-amount">
                      {Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      }).format(item)}{" "}
                      <Currency>{rocketmanTransport.tournamentInfoCurrency}</Currency>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="tournament-info-rules">
          <h5>{translate("rules")}</h5>
          <p>{rocketmanTransport.tournamentDescription}</p>
        </div>
      </div>
    </div>
  );
};

export default TournamentCounter;
