 var config = {
    "us": {
      "chatServer": "wss://chat.elbetdev.com:8765",
      "gameServers": "https://server.rocketman.us.elbetdev.com:9223"
    },
    "default": {
      "chatServer": "wss://chat.elbetdev.com:8765",
      "gameServers": "https://server.rocketman.elbetdev.com:9223"
    }
  }

export default config;
