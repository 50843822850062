import React from 'react';
import translate from '../../services/Translation/lang';
import { useRocketmanTransport } from "../rocketman/RocketmanTransportContext";


export default function OhSnapText() {

  const rocketmanContext = useRocketmanTransport();
  const mp = rocketmanContext.mp;

  const OhSnapImage = "img/oh-snap.png";

  return (
    <div className="oh-snap-pop-up-body">
      <div className="oh-snap-pop-up-box">
        <p>{translate('oh_snap')}</p>
        <h2>{mp && mp.toFixed(2)}x</h2>
        <h5>{translate('it_blew_up')}</h5>
        <img src={OhSnapImage} alt="oh-snap" />
      </div>
    </div>
  )
}
