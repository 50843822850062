import moment from "moment";
import React, { useEffect, useState } from "react";
import { currencyFormatter } from "../../services/Helpers/NumberFormatter";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import BetWinsItem from "../BetWinsItem";

const TopWinsTab = ({timeFilter}) => {

  const [bets, setBets] = useState([]);
  const currency = rocketmanTransport.myCurrency;
  const language = rocketmanTransport.launcherLanguage || 'en';

  useEffect(() => {
    switch (timeFilter) {
      case translate('week'):
        rocketmanTransport.requestTopWinsWeekly();
        break;
      case translate('month'):
        rocketmanTransport.requestTopWinsMonthly();
        break;
      case translate('year'):
        rocketmanTransport.requestTopWinsYearly();
        break;
      default:
        break;
    }
  },[timeFilter]);

  window.setTopWins = setBets;

  return (
    <div>
      {bets.map((bet,index) => {
        return (
          <BetWinsItem key={index} avatar={`avatar${bet.avatar_id}`} username={bet.username} ticketId={bet.ticket_id} 
            flagCode={bet.country_code && bet.country_code == 'XK' && language === 'sr' ? 'rs' : bet.country_code} 
            cashedOut={Number(bet.mp).toFixed(2)} 
            winAmount={rocketmanTransport.myCurrency == bet.currency_code ? currencyFormatter(Number(bet.win)) :  currencyFormatter(Number(Number(bet.win_eur) * Number(rocketmanTransport.myExchangeRate)))} 
            betWin={Number(Number(bet.win_eur) * Number(rocketmanTransport.myExchangeRate))} currency={currency} roundId={bet.round_id} 
            winEur={bet.win_eur}
            betEur={bet.bet_eur}
            betTime={moment.utc(bet.created_time).local().format("DD.MM.  HH:mm")}
            betCurrency={bet.currency_code}
            win={bet.win}
            bet={bet.bet} 
            isVip={bet.avatar_id > 49 ? true : false}/>
        )
      })}
    </div>
  );
};

export default TopWinsTab;
