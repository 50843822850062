import React, { useRef, useState } from "react";
import Moment from "react-moment";
import ReactHtmlParser from 'react-html-parser'; 
import avatars from "../model/avatars";
import Flag from "react-world-flags";
import rocketmanTransport from "../services/RocketmanTransport";
import { useEffect } from "react";
import storage from "../services/Storage/Storage";
import moment from "moment";
import "moment/locale/sr";
import "moment/locale/ar";
import "moment/locale/bg";
import "moment/locale/de";
import "moment/locale/el";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/he";
import "moment/locale/hr";
import "moment/locale/hu";
import "moment/locale/it";
import "moment/locale/mk";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/ro";
import "moment/locale/ru";
import "moment/locale/sq";
import "moment/locale/sv";
import "moment/locale/tr";
import "moment/locale/uk";
import "moment/locale/zh-cn";
import "moment/locale/en-gb";
import translate from "../services/Translation/lang";
import { currencyFormatter } from "../services/Helpers/NumberFormatter";
import Currency from "./Currency/Currency";
import MeteorShowerMessage from "./MeteorShower/MeteorShowerMessage";
import { showerMessageHelper } from "../services/Helpers/StringHelper";

// props
// avatar - user avatar
// flagCode - user country flag
// username
// entery - text of the message
// number of likes
// sharedBet
// if sharedBet is true message will be in form of share bet
// cashedOut
// round - number of round id
// winAmount
// betAmount
// toUser - username of winning user

const UserMessage = (props) => {
  const [count, setCount] = useState(props.numberOfLikes || 0);
  const [liked, setLiked] = useState(false);
  const [image, setImage] = useState(props?.gif);
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);

  const myUsername = rocketmanTransport.username;

  function handleClick() {
    if (props.shower) {
      rocketmanTransport.requestChatLike(props.id, true, props.entry);
    } else {
      rocketmanTransport.requestChatLike(props.id);
    }
    
    if (!liked) {
      setCount(count + 1);
      storage.set(`myMsg-${myUsername}-${props.id}`, 1);
    }
    if (liked) {
      setCount(count - 1);
      storage.remove(`myMsg-${myUsername}-${props.id}`, 1);
    }
    setLiked(!liked);
  }

  window.setUserMsgLang = setLanguage;

  useEffect(() => {
    setImage(props?.gif);
  },[props?.gif]);

  useEffect(() => {
    if (language === "CN") {
      setLanguage("zh-cn");
    }

    if (language === "rs") {
      setLanguage("sr");
    }

    setCount(props.numberOfLikes || 0);
    if (storage.get(`myMsg-${myUsername}-${props.id}`)) {
      setLiked(true);
    }
  }, [props.numberOfLikes, language]);

  const highLight = (mp) => {
    if (Number(mp) > 2 && Number(mp) <= 9.99) return "win";
    if (Number(mp) > 9.99 && Number(mp) <= 99.99) return "win-big";
    if (Number(mp) > 99.99) return "win-legendary";

    return "win-small";
  };

  // if props.special
  // zastavica ikona BOT
  // username ROCKETMAN
  // Background poruke

  return (
    <div className="user-message">
      <p className="message-time">
        {moment(props.time).locale(moment.locale(language)).fromNow()}
      </p>
      <div className="message-avatar-holder">
        <div className="message-avatar">
        {props.special > 0 && props.username === "ROCKETMAN" ? (
          <img className="rocketman-img" src={"tex/rocket.png"} alt="" />
        ) :
          <img src={avatars[props.avatar]} alt="" />}
        </div>
        <p className="message-username">
          {props.username}
          {props.isVip ? (
            <img
              className="message-vip-avatar-marker"
              src="svg/crown.svg"
              alt="crown"
            />
          ) : null}
        </p>
        {props.special > 0 && props.username === "ROCKETMAN" ? (
          <div className="flag-bot">BOT</div>
        ) : props.flagCode === "XS" ? (
          <Flag className="xs-flag" code={"RU"} height="15" width="21" />
        ) : (
          <Flag code={props.flagCode} height="15" width="21" />
        )}
        {props.sharedBet.toUser ? (
          <p className="message-to-user">@{props.sharedBet.toUser}</p>
        ) : null}
      </div>
      <div className="my-message-holder">
        <div className="my-message-like-holder">
          <div
            className={
              count === 0
                ? "d-none"
                : count === 1 && liked
                ? "d-none"
                : count === 1 && !liked
                ? "animated fadeInLeft"
                : count >= 1 && liked
                ? "animated fadeOutLeft"
                : count >= 2 && !liked
                ? "animated fadeInLeft"
                : "d-none"
            }
          >
            <div className="my-message-number-of-likes-holder">
              <div className="my-message-number-of-likes">
                <img
                  src="svg/like-yellow.png"
                  alt="like_yellow"
                  width="15"
                  height="17"
                />
              </div>
              <span className="">{count}</span>
            </div>
          </div>
          <div className="my-message-number-of-likes-clicked-holder">
            <button
              onClick={() => handleClick()}
              className={"my-message-like " + (liked ? "liked" : "")}
            >
              {liked ? (
                <img
                  src="svg/like-yellow.png"
                  alt="like_yellow"
                  width="15"
                  height="17"
                />
              ) : (
                <img src="svg/like.png" alt="like" />
              )}
            </button>
            {liked ? <div>{count}</div> : null}
          </div>
        </div>
        {image ? (
          <div className="my-message-gif">
            <img src={image} alt="gif" onError={() => setImage("")} />
          </div>
        ) : props.sharedBet ? (
          <div className="my-message-shared">
            <div className="my-message-shared-top">
              <p>{props.sharedBet.entry}</p>
            </div>
            <div className="my-message-shared-bottom">
              <div className="mmsb-left">
                <div className="mmsb-info">
                  <p className={"mmsb-info-title " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("cashed_out")}</p>
                  <p
                    className={[
                      "mmsb-info-content",
                      highLight(props.sharedBet.cashedOut),
                    ].join(" ")}
                  >
                    {props.sharedBet.cashedOut}x
                  </p>
                </div>
                <div className="mmsb-info-divider"></div>
                <div className="mmsb-info">
                  <p className={"mmsb-info-title " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("round")}:</p>
                  <p className="mmsb-info-content">{props.sharedBet.round}</p>
                </div>
              </div>
              <div className="mmsb-right">
                <div className="mmsb-info">
                  <p className={"mmsb-info-title " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("win_amount")}</p>
                  {props.sharedBet.betCurrency ==
                  rocketmanTransport.myCurrency ? (
                    <p className="mmsb-info-content">
                      {currencyFormatter(
                        Number(props.sharedBet.winAmount)
                      )}{" "}
                      <Currency>{rocketmanTransport.myCurrency}</Currency>
                    </p>
                  ) : (
                    <p className="mmsb-info-content">
                      {currencyFormatter(
                        Number(
                          Number(props.sharedBet.winEur) *
                            Number(rocketmanTransport.myExchangeRate)
                        )
                      )}{" "}
                      <Currency>{rocketmanTransport.myCurrency}</Currency>
                    </p>
                  )}
                </div>
                <div className="mmsb-info-divider"></div>
                <div className="mmsb-info">
                  <p className={"mmsb-info-title " + (rocketmanTransport.r7css ? "r7css" : "")}>
                    {translate("bet_bets_tab")}:
                  </p>
                  {props.sharedBet.betCurrency ==
                  rocketmanTransport.myCurrency ? (
                    <p className="mmsb-info-content">
                      {currencyFormatter(
                        Number(props.sharedBet.betAmount)
                      )}{" "}
                      <Currency>{rocketmanTransport.myCurrency}</Currency>
                    </p>
                  ) : (
                    <p className="mmsb-info-content">
                      {currencyFormatter(
                        Number(
                          Number(props.sharedBet.betEur) *
                            Number(rocketmanTransport.myExchangeRate)
                        )
                      )}{" "}
                      <Currency>{rocketmanTransport.myCurrency}</Currency>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <button className="provably-fair-shared">
              <img src="svg/provably-fair-icon.png" alt="provably-fair-icon" />
            </button>
          </div>
        ) : (
          <div className={props.special > 0 && !props.shower ? `my-message special-${props.special}` : props.shower ? 
            "my-message my-message-shower" : "my-message"}>
            { props.special > 0 && !props.shower ? (<p>{ ReactHtmlParser(props.entry) }</p>) : props.shower ? 
              <MeteorShowerMessage claims={props.claims} message={showerMessageHelper(props.entry)} /> :
             (<p>{props.entry}</p>)} 
          </div>
        )}
      </div>
    </div>
  );
};

export default UserMessage;
