import React from "react";
import avatars from "../model/avatars";
import Flag from "../components/Flag/Flag";
import { handleRank } from "../services/Helpers/HandleRank";
import Currency from "./Currency/Currency";
import { currencyFormatter } from "../services/Helpers/NumberFormatter";
import rocketmanTransport from "../services/RocketmanTransport";

const TournamentPrevTr = (props) => {
  return (
    <tr className={"all-bets-tr tournament-current-tr " + (rocketmanTransport.r7css ? "r7css" : "")}>
      <td>
        <div className="d-flex align-items-center">
          <span className="tournament-current-tr-rank">
            {Number(props.rank)}
          </span>
          {Number(props.rank) <= 60 ? (
            <img
              className={
                "tournament-rank-image " + handleRank(Number(props.rank))
              }
              src={`svg/tournament/ranks/${handleRank(
                Number(props.rank)
              )}.png`}
              alt="tournament-first"
            />
          ) : null}
        </div>
      </td>
      <td>
        <div className="all-bets-td-holder">
          <div className="all-bets-tr-avatar">
            <img src={avatars[props.avatar]} alt="" />
          </div>
          <span className="all-bets-tr-username tournament-prev-tr-username">
            {props.username}
            {props.isVip ? (
              <img
                className="table-vip-avatar-marker"
                src="svg/crown.svg"
                alt="crown"
              />
            ) : null}
            <br />
            <b>
              <img
                className="tournament-prev-tr-username-image"
                src="svg/tournament/points-icon.png"
                alt="points-icon"
              />
              {currencyFormatter(props.points)}
            </b>
          </span>
          <div className="all-bets-tr-flag tournament-tr-flag">
            {props.flagCode === "XS" ? (
              <Flag className="xs-flag" code={"RU"} height="10" width="14" />
            ) : (
              <Flag code={props.flagCode} height="10" width="14" />
            )}
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex justify-content-between">
          <div>
            <span className="tournament-tr-prize">
              {currencyFormatter(props.prize)}
            </span>{" "}
            <Currency className="tournament-tr-currency">{props.currency}</Currency>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default TournamentPrevTr;
