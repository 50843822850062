import React from "react";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";
import rocketmanTransport from "../../services/RocketmanTransport";
import soundFxPlayer from "../../services/Audio/SoundFxPlayer";
import translate from "../../services/Translation/lang";
import Currency from "../Currency/Currency";
import { currencyFormatter } from "../../services/Helpers/NumberFormatter";

const AlienSaucerFreeBets = ({
  show,
  promoText,
  bonusCredit,
  bonusCash,
  startTheRound,
  setStartButton,
  setShowAlienSaucerPromoNotif,
  setShowFreeBetsNotif,
  showStartRoundParams,
  freeBetsMsg,
  currency,
}) => {
  const handleStartFreeBets = () => {
    rocketmanTransport.startPlayFreeBets();
  };
  const AlienSaucerFreeBetsImage = "img/alien-saucer-free-bets.png";
  const AlienSaucerFreeBetsImageStart = "img/alien-saucer-free-bets-start.png";

  if (!show) return null;

  return (
    <div className="pop-up over-popup blured-popup new-popup">
      <div className="astronaut-pop-up-body new-popup-body">
        {startTheRound ? (
          <img
            className="new-popup-img"
            src={AlienSaucerFreeBetsImageStart}
            alt="astronaut-promo-credit"
          />
        ) : (
          <img
            className="new-popup-img"
            src={AlienSaucerFreeBetsImage}
            alt="astronaut-promo-credit"
          />
        )}
        <div className="new-popup-wrapper">
          <div className="pop-up-promo-text alien-saucer-text">
            <div className="pop-up-promo-text-holder">
              <h1 className="pupt-vissible">{promoText}</h1>
              <h1 className="pupt-hidden">{promoText}</h1>
            </div>
          </div>
          <div className="pop-up-message-box">
            {startTheRound ? null : (
              <div className="pop-up-promo-bonus">
                <div className="pop-up-promo-bonus-holder">
                  <h1 className="pupb-vissible">
                    +{bonusCash} {translate("at")}{" "}
                    {currencyFormatter(bonusCredit)}{" "}
                    <Currency>{currency}</Currency>
                  </h1>
                  <h1 className="pupb-hidden">
                    +{bonusCash} {translate("at")}{" "}
                    {currencyFormatter(bonusCredit)}{" "}
                    <Currency>{currency}</Currency>
                  </h1>
                </div>
              </div>
            )}
            {startTheRound ? (
              <p className="pop-up-message-description">
                {translate("free_bets_desc")}
              </p>
            ) : (
              <p className="pop-up-message-description">
                {translate("congratulations_you_have_won_free_bets")}{" "}
                {currencyFormatter(bonusCredit)} <Currency>{currency}</Currency>{" "}
                {translate("at")} {currencyFormatter(bonusCash)}{" "}
                {translate("game_round")}
              </p>
            )}
          </div>
          {startTheRound ? (
          <div className="pop-up-close new-pop-up-close">
            <PrimaryButton
              title={translate("yes_start_the_round")}
              onClick={() => {
                handleStartFreeBets(); /* setStartButton(false) */
              }}
            />
            <SecondaryButton
              title={translate("not_right_now")}
              onClick={() => setShowFreeBetsNotif(false)}
            />
          </div>
        ) : (
          <div className="pop-up-close new-pop-up-close">
            <PrimaryButton
              title={translate("ok_thanks")}
              onClick={() => {
                soundFxPlayer.play("selectBTN_1");
                setShowFreeBetsNotif(false);
                showStartRoundParams();
              }}
            />
          </div>
        )}
        </div>
       
      </div>
    </div>
  );
};

export default AlienSaucerFreeBets;
